import React, { useState, useEffect, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Element, scroller } from "react-scroll";
import { SelectDatepicker } from "react-select-datepicker";
import Header from "./header";

const EntryPage = (props) => {
  function dynamicHeight(errorCount) {
    let height;

    if (height !== document.getElementById("container").offsetHeight) {
      height = document.getElementById("container").offsetHeight;
      window.parent.postMessage(
        {
          frameHeight: height + errorCount,
        },
        "*"
      );
      console.log(height);
      console.log(errorCount);
      console.log(height + errorCount); // check the message is being sent correctly
    }
  }
  useEffect(() => {
    // scroller.scrollTo("sectionElement", {
    //   duration: 1000,
    //   delay: 500,
    //   offset: -100,
    //   smooth: true,
    // })
    dynamicHeight(0);
    const target = document.querySelector("main");
    target.scrollIntoView();
    // scrollIntoView(document.querySelector("main"), {
    //   behavior: "smooth",
    //   block: "start",
    //   inline: "start",
    // })
  }, []);
  const {
    register,
    getFieldState,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [birthValue, setBirthValue] = useState(null);
  const [dobError, setDobError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  // const [birthday, setBirthday] = useState(null)
  const [invalidBarcode, setInvalidBarcode] = useState(false);
  const [barcodeMessage, setBarcodeMessage] = useState("");
  const [errorMessageState, setErrorMessageState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [defaultError, setDefaultError] = useState(false);
  const [loading, setLoading] = useState(false);

  const barcodeRef = useRef(null);
  const recaptchaRef = useRef();

  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    // let birthday;
    // if (birthValue !== null) {
    //   const months = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December",
    //   ];
    //   const month = months[birthValue.getMonth()];
    //   const day = birthValue.getDate();
    //   const year = birthValue.getFullYear();
    //   birthday = `${month}-${day}-${year}`;
    //   var age = calculateAge(birthday);
    //   if (birthday != null && age >= 18 && age <= 125) {
        setDobError(false);
        setAgeError(false);
        for (var value in data) {
          if (typeof data[value] === "string") {
            data[value] = data[value].toLowerCase();
          }
        }
        // await recaptchaRef.current
        //   .executeAsync()
        //   .then((captchaToken) => {
        //     // console.log(captchaToken)
        //     scroller.scrollTo("elementTop", {
        //       duration: 1000,
        //       delay: 100,
        //       smooth: true,
        //     });
        //     if (captchaToken) {
        //       setLoading(true);
        //       data.reCaptchaToken = captchaToken;
        //       if (data.marsPrivacyPolicy === true) data.marsPrivacyPolicy = "1";
        //       else data.marsPrivacyPolicy = "0";
              // console.log(data)
              // var winnerConfig = {
              //   method: "post",
              //   url: "http://localhost:5000/getcoupon",
              //   // Stage
              //   // url: "https://stage.api.promo.galaxychocolate.co.uk/getcoupon",
              //   // Prod
              //   // url: "https://api.promo.galaxychocolate.co.uk/getcoupon",
              //   headers: {
              //     "Content-Type": "application/json",
              //   },
              //   data: data,
              // };
              // axios(winnerConfig)
              //   .then((response) => {
              //     // console.log(response)
              //     // console.log(birthday)
              //     let dob = `${new Date(birthday).getDate()}-${
              //       new Date(birthday).getMonth() + 1
              //     }-${new Date(birthday).getFullYear()}`;
              //     // console.log(dob)
              //     setLoading(false);
              //     if (response.data.error === false) {
                    setErrorMessageState(false);
                    setDefaultError(false);
                    props.setEntryPage(false);
                    props.setClaimPage({ state: true, message: null });
                    // window.dataLayer = window.dataLayer || [];
                    // window.dataLayer.push({
                    //   event: "emailsubmit",
                    //   email: data.email,
                    //   first_name: data.firstname,
                    //   last_name: data.lastname,
                    //   Region: data.residency,
                    //   Barcode: data.barcode,
                    //   Phone: data.mobilenumber,
                    //   DOB: dob,
                    //   "Unique ID": response.data.uuid,
                    //   status: "success", //capture either error or success. Error would be a wrong email, wrong format, or someone that did not optin to the T&C
                    //   "marketing optin": data.marsPrivacyPolicy, // value can be 1 or 0. 1 if the box is checked 0 if its not.
                    // });
                //   } else if (response.data.inValidBarcode === true) {
                //     setErrorMessageState(false);
                //     setDefaultError(false);
                //     setBarcodeMessage(response.data.message);
                //     setInvalidBarcode(true);
                //     recaptchaRef.current.reset();
                //     scroller.scrollTo("barcodeElement", {
                //       duration: 500,
                //       delay: 50,
                //       smooth: true,
                //     });
                //   } else if (response.data.limitError === true) {
                //     //User Entry Validation
                //     setInvalidBarcode(false);
                //     setDefaultError(false);
                //     props.setEntryPage(false);
                //     props.setClaimPage({
                //       state: true,
                //       message: response.data.message,
                //     });
                //   } else if (response.data.weeklyLimitError === true) {
                //     setInvalidBarcode(false);
                //     setDefaultError(false);
                //     props.setEntryPage(false);
                //     if (response.data.presentWeek === "week13")
                //       props.setMaxClaimFinalWeekPage(true);
                //     else props.setMaxClaimPage(true);
                //   } else if (response.data.couponLimitError === true) {
                //     setDefaultError(false);
                //     setInvalidBarcode(false);
                //     props.setEntryPage(false);
                //     if (response.data.presentWeek === "week13")
                //       props.setMaxClaimFinalWeekPage(true);
                //     else props.setMaxClaimPage(true);
                //   } else if (response.data.userCreateError === true) {
                //     setInvalidBarcode(false);
                //     setDefaultError(true);
                //     recaptchaRef.current.reset();
                //     scroller.scrollTo("defaultErrorElement", {
                //       duration: 500,
                //       delay: 50,
                //       smooth: true,
                //     });
                //   } else if (response.data.reCaptchaError === true) {
                //     setInvalidBarcode(false);
                //     setDefaultError(true);
                //     recaptchaRef.current.reset();
                //     scroller.scrollTo("defaultErrorElement", {
                //       duration: 500,
                //       delay: 50,
                //       smooth: true,
                //     });
                //   } else if (response.data.weeklyStatusError === true) {
                //     setDefaultError(false);
                //     setInvalidBarcode(false);
                //     props.setEntryPage(false);
                //     props.setMaxClaimFinalWeekPage(true);
                //   } else {
                //     setInvalidBarcode(false);
                //     setDefaultError(true);
                //     recaptchaRef.current.reset();
                //     scroller.scrollTo("defaultErrorElement", {
                //       duration: 500,
                //       delay: 50,
                //       smooth: true,
                //     });
                //   }
                // })
                // .catch((err) => {
                //   console.log(err);
                //   setLoading(false);
                //   // props.setEntryPage(false)
                //   // props.setTechincalDifficultyPage(true)
                // });
    //         } else {
    //           scroller.scrollTo("defaultErrorElement", {
    //             duration: 500,
    //             delay: 50,
    //             smooth: true,
    //           });
    //           setDefaultError(true);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         setDefaultError(true);
    //         recaptchaRef.current.reset();
    //         scroller.scrollTo("defaultErrorElement", {
    //           duration: 500,
    //           delay: 50,
    //           smooth: true,
    //         });
    //       });
    //   } else {
    //     if (birthday == null) {
    //       setDobError(true);
    //       setAgeError(false);
    //     } else setAgeError(true);
    //   }
    // } else {
    //   setDobError(true);
    //   setAgeError(false);
    // }
  };
  const onError = (errors, e) => {
    e.preventDefault();
    let errorCount = Object.keys(errors).length + 1;
    dynamicHeight(errorCount * 24);
  };
  const onDateChange = useCallback((date) => {
    setBirthValue(date);
    setDobError(false);
  }, []);
  const showBirthErrorMessage = () => {
    if (birthValue === null) {
      setDobError(true);
    } else {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = months[birthValue.getMonth()];
      const day = birthValue.getDate();
      const year = birthValue.getFullYear();
      let birthday = `${day}-${month}-${year}`;
      var age = calculateAge(birthday);
      // console.log(age)
      setDobError(false);
      if (age >= 18 && age <= 125) {
        setAgeError(false);
      } else {
        setAgeError(true);
      }
    }
  };

  if (Object.keys(errors).length > 0) {
    console.log("keys", Object.keys(errors).length + 1);
    setTimeout(() => {
      dynamicHeight(Object.keys(errors).length + 1 * 24);
    }, 50);
  }

  return (
    <div id="container">
      <Header />
      {loading && <div className="loading"></div>}
      <Element name="elementTop" id="elementTop"></Element>
      <section className="text-center py-md-5">
        <div className="container px-lg-5 py-4">
          <Element name="sectionElement"></Element>
          <h2>
            Enter your details to
            <span className="text-pink"> gift a free bar </span>
            of Galaxy<sup>&reg;</sup>
            chocolate to a deserving friend and show support for cocoa
            communities.
          </h2>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit, onError)}
            className="entry-form text-start mt-md-4 pt-md-5 pt-4"
          >
            <Element name="barcodeElement"></Element>
            <div className="mb-3">
              <input
                type="text"
                className="form-control text-uppercase"
                id="barcode"
                ref={barcodeRef}
                maxLength={4}
                placeholder="*ENTER THE LAST 4 DIGITS FROM YOUR PROMOTIONAL PACK BARCODE"
                {...register("barcode", {
                  required: "Baracode is required",
                  minLength: {
                    value: 4,
                    message: "Please enter valid Barcode",
                  },
                  pattern: {
                    value: /^[0-9]+$/i,
                    message: "Please enter valid Barcode",
                  },
                })}
              />
              {invalidBarcode === false && errors.barcode && (
                <span className="input-error">{errors.barcode.message}</span>
              )}
              {invalidBarcode && (
                <span className="input-error">{barcodeMessage}</span>
              )}
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control text-uppercase"
                id="fname"
                placeholder="*FIRST NAME"
                {...register("firstname", { required: true, maxLength: 50 })}
              />
              {errors.firstname && (
                <span className="input-error"> First name is required </span>
              )}
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control text-uppercase"
                id="lname"
                placeholder="*LAST NAME"
                {...register("lastname", { required: true, maxLength: 50 })}
              />
              {errors.lastname && (
                <span className="input-error"> Last name is required </span>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="birthdate" className="form-label">
                DATE OF BIRTH
              </label>
              <SelectDatepicker
                id="birthdate"
                value={birthValue}
                onDateChange={onDateChange}
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date()}
                hideLabels
                labels={{
                  dayPlaceholder: "Day",
                  monthPlaceholder: "Month",
                  yearPlaceholder: "Year",
                }}
                order="day/month/year"
              />
              {ageError === false && dobError && (
                <span className="input-error"> Date of birth is required </span>
              )}
              {ageError && (
                <span className="input-error">
                  Sorry you must be 18 or over
                </span>
              )}
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control text-uppercase"
                id="email"
                placeholder="*EMAIL ADDRESS"
                {...register("email", {
                  required: "Email Address is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                })}
              />
              {errors.email && (
                <span className="input-error"> {errors.email.message} </span>
              )}
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control text-uppercase"
                id="mobile"
                placeholder="*MOBILE TELEPHONE NUMBER"
                {...register("mobilenumber", {
                  required: "Mobile telephone number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a number",
                  },
                  minLength: {
                    value: 10,
                    message: "The minimum length is 10",
                  },
                })}
              />
              {errors.mobilenumber && (
                <span className="input-error">
                  {errors.mobilenumber.message}
                </span>
              )}
            </div>
            <div className="mb-3">
              <select
                className="form-select text-uppercase"
                {...register("residency", { required: true })}
              >
                <option value="">--SELECT REGION--</option>
                <option value="uk">Eng/Scot/Wales</option>
                <option value="ni">NI</option>
              </select>

              {errors.residency && (
                <span className="input-error">
                  {" "}
                  Where do you live is required{" "}
                </span>
              )}
            </div>
            <div>
              <label className="form-check-custom">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  {...register("marsPrivacyPolicy", { required: false })}
                />
                <span className="checkmark"></span>
                <span className="check-box-text text-uppercase">
                  I would like to receive personalised marketing offers from
                  Galaxy via email and other electronic channels. (You can
                  withdraw this consent at any time)
                </span>
              </label>
            </div>
            <div className="mb-4">
              <div>
                <label className="form-check-custom mb-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    {...register("terms", {
                      required: true,
                    })}
                  />

                  <span className="checkmark"></span>
                  <span className="check-box-text text-uppercase">
                    I have read the{" "}
                    <a
                      className="hyper-link"
                      href="https://www.galaxychocolate.co.uk/gift-a-bar-tcs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and conditions
                      <span className="visually-hidden">
                        (opens in new window)
                      </span>
                    </a>
                  </span>
                </label>
              </div>
              {errors.terms && (
                <span className="input-error">
                  Terms and conditions is required
                </span>
              )}
            </div>
            {/* <ReCAPTCHA
              sitekey="6LdN87IgAAAAAM0Nds-RvR2L7EKu6quXXLv06kxK"
              size="invisible"
              ref={recaptchaRef}
            /> */}
            <div className="pt-md-5 pt-4 form-button text-center">
              <button
                id="claimButton"
                className="btn btn-pink text-uppercase mb-4"
                type="submit"
                onClick={showBirthErrorMessage}
              >
                GIFT A FREE BAR
              </button>
            </div>
          </form>
          <Element name="defaultErrorElement"></Element>
          {errorMessageState && (
            <span className="input-error fs-3">{errorMessage}</span>
          )}
          {defaultError && (
            <span className="input-error fs-3">
              Oops, there seems to be an error. Please retry submitting the
              form.
            </span>
          )}
        </div>
        <a
          className="hyper-link fs-6"
          href="https://www.galaxychocolate.co.uk/gift-a-bar-tcs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here for full terms and conditions
          <span className="visually-hidden">(opens in new window)</span>
        </a>
        <p className="fs-6 pt-3">
          *We source cocoa under the mass balance method.{" "}
          <span className="d-md-block">
            Find out more about mass balance sourcing at{" "}
            <a
              className="hyper-link"
              href="https://www.rainforest-alliance.org/business/certification/what-is-mass-balance-sourcing/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.ra.org/mb
              <span className="visually-hidden">(opens in new window)</span>
            </a>
            .
          </span>
        </p>
      </section>
    </div>
  );
};
export default EntryPage;
