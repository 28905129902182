import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";
import Header from "./header";

const TechincalDifficultyPage = () => {
  useEffect(() => {
    // scroller.scrollTo("sectionElement", {
    //   duration: 1000,
    //   delay: 500,
    //   smooth: true,
    // })
    let height;

    if (height !== document.getElementById("container").offsetHeight) {
      height = document.getElementById("container").offsetHeight;
      window.parent.postMessage(
        {
          frameHeight: height,
        },
        "*"
      );
      console.log(height); // check the message is being sent correctly
    }
    const target = document.getElementsByTagName("main")[0];
    target.scrollIntoView();
  }, []);
  return (
    <div id="container">
      <Header />
      <section className="text-center py-5">
        <div className="container px-md-5">
          <h4 className="m-lg-5 my-4">
            <Element name="sectionElement"></Element>
            Oops Currently the site is facing some Techincal maintainance, Visit
            us after few minutes.
          </h4>
        </div>
      </section>
    </div>
  );
};
export default TechincalDifficultyPage;
