import React, { useEffect, useState, memo } from "react"
import ReactPlayer from "react-player"
// import url from "../video/test.mp4"

const ReactPlayerComponent = ({ setIsDisable }) => {
  useEffect(() => {
    return () => {
      isPlaying(false)
      isHover(false)
      setReady(false)
    }
  }, [])

  const [playing, isPlaying] = useState(false)
  const [hover, isHover] = useState(false)
  const [ready, setReady] = useState(false)
  // const url = "https://www.w3schools.com/tags/movie.mp4"
  const url = "https://youtu.be/toqRoNdJg0s"

  return (
    <section
      className="video-player position-relative"
      onMouseOver={() => {
        // console.log("hover")
        isHover(true)
        setTimeout(() => {
          isHover(false)
        }, 1000)
      }}
      onClick={() => {
        isHover(true)
        setTimeout(() => {
          isHover(false)
        }, 1000)
      }}
      onMouseOut={() => isHover(false)}
    >
      <ReactPlayer
        className="video"
        width="100%"
        url={url}
        onReady={() => {
          // console.log("ready")
          setReady(true)
        }}
        playing={playing}
        onEnded={() => {
          setIsDisable(false)
          isPlaying(false)
        }}
        config={{
          youtube: {
            playerVars: {
              showinfo: 1,
              modestbranding: 0,
              fs: 0,
              disablekb: 1,
              rel: 1,
              preload: true,
            },
          },
        }}
      />
      {/* <div className="player-controls">
        {ready && (
          <i
            id="play-button"
            className={
              playing
                ? hover
                  ? "fa fa-pause-circle play-button "
                  : "fa fa-pause-circle play-button fadeIn"
                : "fa fa-play-circle play-button"
            }
            onClick={() => {
              isPlaying(!playing)
            }}
          ></i>
        )}
      </div> */}
    </section>
  )
}

export default memo(ReactPlayerComponent)
