import React, { useState } from "react"
import Layout from "../../components/galaxy limited edition components/components/layout"
import VideoPage from "../../components/galaxy limited edition components/components/video-page"
import EntryPage from "../../components/galaxy limited edition components/components/entry-page"
import ClaimPage from "../../components/galaxy limited edition components/components/claim-page"
import MaxClaimPage from "../../components/galaxy limited edition components/components/max-claim-page"
import MaxClaimFinalWeekPage from "../../components/galaxy limited edition components/components/max-claim-page-final-week"
import PromotionClosingPage from "../../components/galaxy limited edition components/components/promotion-close"
import TechincalDifficultyPage from "../../components/galaxy limited edition components/components/technical-difficulty-page"

const IndexPage = () => {
  const [videoPage, setVideoPage] = useState(true)
  const [entryPage, setEntryPage] = useState(false)
  const [claimPage, setClaimPage] = useState({ state: false, message: null })
  const [maxClaimPage, setMaxClaimPage] = useState(false)
  const [maxClaimFinalWeekPage, setMaxClaimFinalWeekPage] = useState(false)
  const [promotionClosingPage, setPromotionClosingPage] = useState(false)
  const [techincalDifficultyPage, setTechincalDifficultyPage] = useState(false)

  return (
    <Layout>
      {videoPage && (
        <VideoPage
          setVideoPage={setVideoPage}
          setEntryPage={setEntryPage}
          setMaxClaimPage={setMaxClaimPage}
          setMaxClaimFinalWeekPage={setMaxClaimFinalWeekPage}
          setTechincalDifficultyPage={setTechincalDifficultyPage}
        />
      )}
      {entryPage && (
        <EntryPage
          setEntryPage={setEntryPage}
          setClaimPage={setClaimPage}
          setMaxClaimPage={setMaxClaimPage}
          setMaxClaimFinalWeekPage={setMaxClaimFinalWeekPage}
          setTechincalDifficultyPage={setTechincalDifficultyPage}
        />
      )}
      {claimPage.state && <ClaimPage message={claimPage.message} />}
      {maxClaimPage && <MaxClaimPage />}
      {maxClaimFinalWeekPage && <MaxClaimFinalWeekPage />}
      {promotionClosingPage && <PromotionClosingPage />}
      {techincalDifficultyPage && <TechincalDifficultyPage />}
    </Layout>
  )
}
export default IndexPage
