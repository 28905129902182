import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";
import Header from "./header";

const PromotionClosingPage = () => {
  useEffect(() => {
    // scroller.scrollTo("sectionElement", {
    //   duration: 1000,
    //   delay: 500,
    //   offset: -100,
    //   smooth: true,
    // })
    let height;

    if (height !== document.getElementById("container").offsetHeight) {
      height = document.getElementById("container").offsetHeight;
      window.parent.postMessage(
        {
          frameHeight: height,
        },
        "*"
      );
      console.log(height); // check the message is being sent correctly
    }
    const target = document.getElementsByTagName("main")[0];
    target.scrollIntoView();
  }, []);
  return (
    <div id="container">
      <Header />
      <section className="text-center py-5">
        <div className="container px-md-5">
          <h4 className="m-lg-5 my-4">
            <Element name="sectionElement"></Element>
            Sorry, the{" "}
            <span className="text-pink text-uppercase">gift a bar</span>{" "}
            promotion has now closed, but you can still learn about all the
            great things we are doing to{" "}
            <span className="text-pink">support cocoa communities.</span>
          </h4>

          <h4 className="m-lg-5 my-4">
            Please [
            <a
              className="hyper-link text-decoration-none"
              href="https://www.galaxychocolate.co.uk/our-purpose"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-pink">click here</span>
              <span className="visually-hidden">(opens in a new window)</span>
            </a>
            ]
          </h4>
        </div>
      </section>
    </div>
  );
};
export default PromotionClosingPage;
