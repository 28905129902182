import React, { useState, useEffect } from "react";
import ReactPlayer from "./react-player";
import { Element, scroller } from "react-scroll";
import Header from "./header";

const VideoPage = (props) => {
  useEffect(() => {
    // scroller.scrollTo("sectionElement", {
    //   duration: 1000,
    //   delay: 500,
    //   smooth: true,
    // })
    let height;

    if (height !== document.getElementById("container").offsetHeight) {
      height = document.getElementById("container").offsetHeight;
      window.parent.postMessage(
        {
          frameHeight: height,
        },
        "*"
      );
      console.log(height); // check the message is being sent correctly
    }
    return () => {
      setIsDisable(false);
    };
  }, []);
  const [isDisbale, setIsDisable] = useState(true);

  return (
    <div id="container">
      <Header />
      <section className="text-center py-md-5">
        <div className="container px-md-4 py-4">
          {/* <Element name="sectionElement"></Element> */}

          <p className="bold-content">
            Today we’re proud to say that 100% of the cocoa sourced<sup>*</sup>{" "}
            for our Galaxy® Smooth Milk products is responsibly sourced and we
            are committed to responsibly sourcing all our cocoa by 2025.
            <span className="d-block">
              That’s why you can enjoy the great taste of Galaxy® Smooth Milk
              products knowing that we are committed to meaningfully improving
              the lives of cocoa farming communities and that we are making
              chocolate better one piece at a time through multiple initiatives.
            </span>
          </p>
          <div className="text-start">
            <p className="bold-content pt-md-4 pt-3">We are:</p>
            <ul>
              <ul>
                <li>
                  <p>
                    Helping communities flourish through quality education in
                    Cote d’Ivoire
                  </p>
                </li>
                <li>
                  <p>
                    Using our responsible sourcing program to source quality
                    cocoa
                  </p>
                </li>
                <li>
                  {" "}
                  <p>Helping women thrive in the cocoa farm community</p>
                </li>
                <li>
                  {" "}
                  <p>
                    Coaching cocoa farmers to help them grow stronger better
                    harvests
                  </p>
                </li>
              </ul>
            </ul>
          </div>

          <p className="bold-content">
            You can show your support for cocoa communities by choosing Galaxy®
            Smooth Milk products, gifting the great taste of a Galaxy® Smooth
            Milk product to a friend, and starting a conversation on why cocoa
            sustainability matters.
          </p>
          <p>
            Learn more by watching the video below and share it on your social
            channels to spread the word that 100% of the cocoa sourced
            <sup>*</sup> for Galaxy® Smooth Milk products is responsibly
            sourced.
          </p>
        </div>

        <ReactPlayer setIsDisable={setIsDisable} />
        <p className="py-md-5 py-3">
          To learn more about the Galaxy<sup>&reg;</sup> brand's approach to
          <span className="d-block">
            cocoa sustainability –
            <a
              className="hyper-link"
              href="https://www.galaxychocolate.co.uk/our-purpose"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
              <span className="visually-hidden">(opens in a new window)</span>
            </a>
          </span>
        </p>
        <p className="fs-6 pb-3">
          <span className="text-decoration-underline">Note</span>: Please watch
          the video in order to take part in the campaign!
        </p>
        <button
          // className={
          //   isDisbale
          //     ? "btn btn-pink text-uppercase mb-4 disabled"
          //     : "btn btn-pink text-uppercase mb-4"
          // }
          className="btn btn-pink text-uppercase mb-4"
          onClick={() => {
            props.setVideoPage(false);
            props.setEntryPage(true);
          }}
        >
          GIFT A FREE BAR
        </button>
        <div>
          <a
            className="hyper-link fs-6"
            href="https://www.galaxychocolate.co.uk/gift-a-bar-tcs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here for full terms and conditions
            <span className="visually-hidden">(opens in new window)</span>
          </a>
          <p className="fs-6 pt-3">
            *We source cocoa under the mass balance method.{" "}
            <span className="d-md-block">
              Find out more about mass balance sourcing at{" "}
              <a
                className="hyper-link"
                href="https://www.rainforest-alliance.org/business/certification/what-is-mass-balance-sourcing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.ra.org/mb
                <span className="visually-hidden">(opens in new window)</span>
              </a>
              .
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};
export default VideoPage;
